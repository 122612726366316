import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '../layout/index.vue'

export const constantRoutes = [
    {
        path: '/login',
        component: () => import('../views/login/index.vue'),
        hidden: true
    },

    {
        path: '/404',
        component: () => import('../views/404.vue'),
        hidden: true
    },

    {
      path: '/',
      component: Layout,
      redirect: '/dashboard',
      children: [{
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('../views/dashboard/index.vue'),
        meta: {title: '主页', icon: 'home'}
      }]
    }
]

export const asyncRoutes = [
  {
    path: '/admins',
    component: Layout,
    redirect: '/admins/index',
    name: 'RAdmin',
    children: [
      {
        path: 'index',
        name: 'Admins',
        component: () => import('../views/admins/index.vue'),
        meta: {
          title: '管理员管理',
          icon: 'admin',
          permission: 'admin'
        }
      }
    ]
  },

  {
    path: '/regions',
    component: Layout,
    redirect: '/regions/index',
    name: 'RRegion',
    children: [
      {
        path: 'index',
        name: 'Regions',
        component: () => import('../views/regions/index.vue'),
        meta: {
          title: '大区管理',
          icon: 'category',
          permission: 'region'
        }
      },
      {
        path: 'new',
        component: () => import('../views/regions/new.vue'),
        name: 'NewRegion',
        hidden: true,
        meta: {
          title: '新增大区',
          activeMenu: '/regions/index',
          permission: 'region'
        }
      },
      {
        path: ':id/edit',
        component: () => import('../views/regions/edit.vue'),
        name: 'EditRegion',
        hidden: true,
        meta: {
          title: '编辑大区',
          activeMenu: '/regions/index',
          permission: 'region'
        }
      }
    ]
  },

  {
    path: '/region_offices',
    component: Layout,
    redirect: '/region_offices/index',
    name: 'RRegionOffice',
    children: [
      {
        path: 'index',
        name: 'RegionOffices',
        component: () => import('../views/region_offices/index.vue'),
        meta: {
          title: '大区办事处',
          icon: 'category',
          permission: 'region_office'
        }
      },
      {
        path: 'new',
        component: () => import('../views/region_offices/new.vue'),
        name: 'NewRegionOffice',
        hidden: true,
        meta: {
          title: '新增大区办事处',
          activeMenu: '/region_offices/index',
          permission: 'region_office'
        }
      },
      {
        path: ':id/edit',
        component: () => import('../views/region_offices/edit.vue'),
        name: 'EditRegionOffice',
        hidden: true,
        meta: {
          title: '编辑大区办事处',
          activeMenu: '/region_offices/index',
          permission: 'region_office'
        }
      }
    ]
  },

  {
    path: '/users',
    component: Layout,
    redirect: '/users/index',
    name: 'RUser',
    children: [
      {
        path: 'index',
        name: 'Users',
        component: () => import('../views/users/index.vue'),
        meta: {
          title: '用户管理',
          icon: 'user',
          permission: 'user'
        }
      },
      {
        path: ':id/Detail',
        name: 'UserDetail',
        component: () => import('../views/users/show.vue'),
        hidden: true,
        meta: {
          title: '用户详情',
          activeMenu: '/users/index',
          permission: 'user'
        }
      },
      {
        path: ':id/doctor_user_detail',
        name: 'DoctorUserDetail',
        component: () => import('../views/users/doctor_user_detail.vue'),
        hidden: true,
        meta: {
          title: '顾问用户详情',
          activeMenu: '/users/index',
          permission: 'user'
        }
      },
      {
        path: ':id/normal_user_detail',
        name: 'NormalUserDetail',
        component: () => import('../views/users/normal_user_detail.vue'),
        hidden: true,
        meta: {
          title: '普通用户详情',
          activeMenu: '/users/index',
          permission: 'user'
        }
      },
      {
        path: ':id/delegate_user_detail',
        name: 'DelegateUserDetail',
        component: () => import('../views/users/delegate_user_detail.vue'),
        hidden: true,
        meta: {
          title: '代理用户详情',
          activeMenu: '/users/index',
          permission: 'user'
        }
      },
      {
        path: ':id/edit',
        name: 'EditUser',
        component: () => import('../views/users/edit.vue'),
        hidden: true,
        meta: {
          title: '编辑用户',
          activeMenu: '/users/index',
          permission: 'user'
        }
      }
    ]
  },

  {
    path: '/user_audits',
    component: Layout,
    redirect: '/user_audits/be_delegate/index',
    name: 'RUserAudit',
    children: [
      {
        path: 'be_delegate/index',
        name: 'BeDelegateUserAudits',
        component: () => import('../views/user_audits/index.vue'),
        meta: {
          title: '用户审核管理',
          icon: 'user',
          permission: 'user_audit'
        }
      }
    ]
  },

  {
    path: '/user_tags',
    component: Layout,
    redirect: '/user_tags/index',
    name: 'RUserTag',
    children: [
      {
        path: 'index',
        name: 'UserTags',
        component: () => import('../views/user_tags/index.vue'),
        meta: {
          title: '用户标签管理',
          icon: 'category',
          permission: 'user_tag'
        }
      },
      {
        path: 'new',
        component: () => import('../views/user_tags/new.vue'),
        name: 'NewUserTag',
        hidden: true,
        meta: {
          title: '新增用户标签',
          activeMenu: '/user_tags/index',
          permission: 'user_tag'
        }
      },
      {
        path: ':id/edit',
        component: () => import('../views/user_tags/edit.vue'),
        name: 'EditUserTag',
        hidden: true,
        meta: {
          title: '编辑用户标签',
          activeMenu: '/user_tags/index',
          permission: 'user_tag'
        }
      }
    ]
  },

  {
    path: '/qrcodes',
    component: Layout,
    redirect: '/qrcodes/index',
    name: 'RQrcode',
    children: [
      {
        path: 'index',
        name: 'Qrcodes',
        component: () => import('../views/qrcodes/index.vue'),
        meta: {
          title: '二维码',
          icon: 'qrcode',
          permission: 'qrcode'
        }
      }
    ]
  },

  {
    path: '/export_histories',
    component: Layout,
    redirect: '/export_histories/index',
    name: 'RExportHistory',
    children: [
      {
        path: '',
        name: 'ExportHistory',
        component: () => import('../views/export_histories/index.vue'),
        meta: {
          title: '导出历史管理',
          icon: 'export',
          permission: 'export_history'
        }
      }
    ]
  },

  {
    path: '/addresses',
    component: Layout,
    redirect: '/addresses/index',
    name: 'RAddresses',
    children: [
      {
        path: '',
        name: 'Addresses',
        component: () => import('../views/addresses/index.vue'),
        meta: {
          title: '用户地址管理',
          icon: 'address',
          permission: 'address'
        }
      }
    ]
  },

  {
    path: '/banners',
    component: Layout,
    redirect: '/banners/index',
    name: 'RBanner',
    children: [
      {
        path: 'index',
        name: 'Banners',
        component: () => import('../views/banners/index.vue'),
        meta: {
          title: 'Banner管理',
          icon: 'category',
          permission: 'banner'
        }
      },
      {
        path: 'new',
        component: () => import('../views/banners/new.vue'),
        name: 'NewBanner',
        hidden: true,
        meta: {
          title: '新增Banner',
          activeMenu: '/banners/index',
          permission: 'banner'
        }
      },
      {
        path: ':id/edit',
        component: () => import('../views/banners/edit.vue'),
        name: 'EditBanner',
        hidden: true,
        meta: {
          title: '编辑Banner',
          activeMenu: '/banners/index',
          permission: 'banner'
        }
      }
    ]
  },


  {
    path: '/product_categories',
    component: Layout,
    redirect: '/product_categories/index',
    name: 'RProductCategory',
    children: [
      {
        path: 'index',
        name: 'ProductCategories',
        component: () => import('../views/product_categories/index.vue'),
        meta: {
          title: '商品分类管理',
          icon: 'category',
          permission: 'product_category'
        }
      },
      {
        path: 'new',
        component: () => import('../views/product_categories/new.vue'),
        name: 'NewProductCategory',
        hidden: true,
        meta: {
          title: '新增商品分类',
          activeMenu: '/product_categories/index',
          permission: 'product_category'
        }
      },
      {
        path: ':id/edit',
        component: () => import('../views/product_categories/edit.vue'),
        name: 'EditProductCategory',
        hidden: true,
        meta: {
          title: '编辑商品分类',
          activeMenu: '/product_categories/index',
          permission: 'product_category'
        }
      }
    ]
  },

  {
    path: '/product_comments',
    component: Layout,
    redirect: '/product_comments/index',
    name: 'RProductComment',
    children: [
      {
        path: 'index',
        name: 'ProductComments',
        component: () => import('../views/product_comments/index.vue'),
        meta: {
          title: '商品评论管理',
          icon: 'comment',
          permission: 'product_comment'
        }
      },
      {
        path: ':id/detail',
        name: 'ShowProductComment',
        component: () => import('../views/product_comments/show.vue'),
        hidden: true,
        meta: {
          title: '商品评论详情',
          activeMenu: '/product_comments/index',
          permission: 'product_comment'
        }
      }
    ]
  },

  {
    path: '/products',
    component: Layout,
    redirect: '/products/index',
    name: 'RProduct',
    children: [
      {
        path: 'index',
        name: 'Products',
        component: () => import('../views/products/index.vue'),
        meta: {
          title: '商品管理',
          icon: 'product',
          permission: 'product'
        }
      },
      {
        path: 'new',
        component: () => import('../views/products/new.vue'),
        name: 'NewProduct',
        hidden: true,
        meta: {
          title: '新增商品',
          activeMenu: '/products/index',
          permission: 'product'
        }
      },
      {
        path: ':id/edit',
        component: () => import('../views/products/edit.vue'),
        name: 'EditProduct',
        hidden: true,
        meta: {
          title: '编辑商品',
          activeMenu: '/products/index',
          permission: 'product'
        }
      },
      {
        path: ':id/stock_records',
        component: () => import('../views/product_stock_records/index.vue'),
        name: 'ProductStockRecords',
        hidden: true,
        meta: {
          title: '商品库存',
          activeMenu: '/products/index',
          permission: 'product'
        }
      }
    ]
  },

  {
    path: '/article_categories',
    component: Layout,
    redirect: '/article_categories/index',
    name: 'RArticleCategory',
    children: [
      {
        path: 'index',
        name: 'ArticleCategories',
        component: () => import('../views/article_categories/index.vue'),
        meta: {
          title: '文章分类管理',
          icon: 'category',
          permission: 'article_category'
        }
      },
      {
        path: 'new',
        component: () => import('../views/article_categories/new.vue'),
        name: 'NewArticleCategory',
        hidden: true,
        meta: {
          title: '新增文章分类',
          activeMenu: '/article_categories/index',
          permission: 'article_category'
        }
      },
      {
        path: ':id/edit',
        component: () => import('../views/article_categories/edit.vue'),
        name: 'EditArticleCategory',
        hidden: true,
        meta: {
          title: '编辑文章分类',
          activeMenu: '/article_categories/index',
          permission: 'article_category'
        }
      }
    ]
  },

  {
    path: '/articles',
    component: Layout,
    redirect: '/articles/index',
    name: 'RArticle',
    children: [
      {
        path: 'index',
        name: 'Articles',
        component: () => import('../views/articles/index.vue'),
        meta: {
          title: '文章管理',
          icon: 'writing',
          permission: 'article'
        }
      },
      {
        path: 'new',
        component: () => import('../views/articles/new.vue'),
        name: 'NewArticle',
        hidden: true,
        meta: {
          title: '新增文章',
          activeMenu: '/articles/index',
          permission: 'article'
        }
      },
      {
        path: ':id/edit',
        component: () => import('../views/articles/edit.vue'),
        name: 'EditArticle',
        hidden: true,
        meta: {
          title: '编辑文章',
          activeMenu: '/articles/index',
          permission: 'article'
        }
      }
    ]
  },

  {
    path: '/coupons',
    component: Layout,
    redirect: '/coupons/index',
    name: 'RCoupon',
    children: [
      {
        path: 'index',
        name: 'Coupons',
        component: () => import('../views/coupons/index.vue'),
        meta: {
          title: '优惠券管理',
          icon: 'coupon',
          permission: 'coupon'
        }
      }
    ]
  },

  {
    path: '/coupon_templates',
    component: Layout,
    redirect: '/coupon_templates/index',
    name: 'RCouponTemplate',
    children: [
      {
        path: 'index',
        name: 'CouponTemplates',
        component: () => import('../views/coupon_templates/index.vue'),
        meta: {
          title: '优惠券模版管理',
          icon: 'template',
          permission: 'coupon_template'
        }
      },
      {
        path: 'new',
        component: () => import('../views/coupon_templates/new.vue'),
        name: 'NewCouponTemplate',
        hidden: true,
        meta: {
          title: '新增优惠券模版',
          activeMenu: '/coupon_templates/index',
          permission: 'coupon_template'
        }
      },
      {
        path: ':id/edit',
        component: () => import('../views/coupon_templates/edit.vue'),
        name: 'EditCouponTemplate',
        hidden: true,
        meta: {
          title: '编辑优惠券模版',
          activeMenu: '/coupon_templates/index',
          permission: 'coupon_template'
        }
      },
      {
        path: ':id/issue',
        component: () => import('../views/coupon_templates/issue.vue'),
        name: 'issueCouponTemplate',
        hidden: true,
        meta: {
          title: '发放优惠卷',
          activeMenu: '/coupon_templates/index',
          permission: 'coupon_template'
        }
      },
      {
        path: ':id/issue_records',
        component: () => import('../views/coupon_templates/issue_records.vue'),
        name: 'issueRecordsCouponTemplate',
        hidden: true,
        meta: {
          title: '发放优惠卷记录',
          activeMenu: '/coupon_templates/index',
          permission: 'coupon_template'
        }
      }
    ]
  },

  {
    path: '/orders',
    component: Layout,
    redirect: '/orders/index',
    name: 'ROrder',
    children: [
      {
        path: 'index',
        name: 'Orders',
        component: () => import('../views/orders/index.vue'),
        meta: {
          title: '订单管理',
          icon: 'order',
          permission: 'order'
        }
      },
      {
        path: 'statistic',
        name: 'OrderStatistic',
        component: () => import('../views/orders/statistic.vue'),
        hidden: true,
        meta: {
          title: '订单统计',
          activeMenu: '/orders/index',
          permission: 'order'
        }
      },
      {
        path: ':id/detail',
        name: 'ShowOrder',
        component: () => import('../views/orders/show.vue'),
        hidden: true,
        meta: {
          title: '订单详情',
          activeMenu: '/orders/index',
          permission: 'order'
        }
      },
      {
        path: ':id/shipping',
        component: () => import('../views/orders/shipping.vue'),
        name: 'NewOrderShipping',
        hidden: true,
        meta: {
          title: '订单发货',
          activeMenu: '/orders/index',
          permission: 'order'
        }
      },
      {
        path: ':id/other_shipping',
        component: () => import('../views/orders/other_shipping.vue'),
        name: 'NewOrderOtherShipping',
        hidden: true,
        meta: {
          title: '订单线下发货',
          activeMenu: '/orders/index',
          permission: 'order'
        }
      }
    ]
  },

  {
    path: '/order_refund_applies',
    component: Layout,
    redirect: '/order_refund_applies/index',
    name: 'ROrderRefundApply',
    children: [
      {
        path: 'index',
        name: 'OrderRefundApplies',
        component: () => import('../views/order_refund_applies/index.vue'),
        meta: {
          title: '订单退货申请',
          icon: 'order',
          permission: 'order_refund_apply'
        }
      }
    ]
  },

  {
    path: '/shippers',
    component: Layout,
    redirect: '/shippers/index',
    name: 'RShipper',
    children: [
      {
        path: 'index',
        name: 'Shippers',
        component: () => import('../views/shippers/index.vue'),
        meta: {
          title: '发货人管理',
          icon: 'shipper',
          permission: 'shipper'
        }
      },
      {
        path: 'new',
        component: () => import('../views/shippers/new.vue'),
        name: 'NewShipper',
        hidden: true,
        meta: {
          title: '新增发货人',
          activeMenu: '/shippers/index',
          permission: 'shipper'
        }
      },
      {
        path: ':id/edit',
        component: () => import('../views/shippers/edit.vue'),
        name: 'EditShipper',
        hidden: true,
        meta: {
          title: '编辑发货人',
          activeMenu: '/shippers/index',
          permission: 'shipper'
        }
      }
    ]
  },

  {
    path: '/shipping_vendor_rules',
    component: Layout,
    redirect: '/shipping_vendor_rules/index',
    name: 'RShippingVendorRule',
    children: [
      {
        path: 'index',
        name: 'ShippingVendorRules',
        component: () => import('../views/shipping_vendor_rules/index.vue'),
        meta: {
          title: '快递供应商规则管理',
          icon: 'shipper',
          permission: 'shipping_vendor_rule'
        }
      },
      {
        path: 'new',
        component: () => import('../views/shipping_vendor_rules/new.vue'),
        name: 'NewShippingVendorRule',
        hidden: true,
        meta: {
          title: '新增快递供应商规则',
          activeMenu: '/shipping_vendor_rules/index',
          permission: 'shipping_vendor_rule'
        }
      },
      {
        path: ':id/edit',
        component: () => import('../views/shipping_vendor_rules/edit.vue'),
        name: 'EditShippingVendorRule',
        hidden: true,
        meta: {
          title: '编辑快递供应商规则',
          activeMenu: '/shipping_vendor_rules/index',
          permission: 'shipping_vendor_rule'
        }
      }
    ]
  },

  {
    path: '/china_regions',
    component: Layout,
    redirect: 'noRedirect',
    name: 'RChinaRegion',
    meta: {
      title: '地址数据',
      icon: 'china_region',
      permission: 'china_region'
    },
    children: [
      {
        path: 'provinces/index',
        component: () => import('../views/china_regions/provinces/index.vue'),
        name: 'Provinces',
        meta: {
          title: '省',
          permission: 'china_region'
        }
      },
      {
        path: 'provinces/new',
        component: () => import('../views/china_regions/provinces/new.vue'),
        name: 'NewProvince',
        hidden: true,
        meta: {
          title: '新增省',
          activeMenu: '/china_regions/provinces/index',
          permission: 'china_region'
        }
      },
      {
        path: 'provinces/:id/edit',
        component: () => import('../views/china_regions/provinces/edit.vue'),
        name: 'EditProvince',
        hidden: true,
        meta: {
          title: '编辑省',
          activeMenu: '/china_regions/provinces/index',
          permission: 'china_region'
        }
      },
      {
        path: 'cities/index',
        component: () => import('../views/china_regions/cities/index.vue'),
        name: 'Cities',
        meta: {
          title: '市',
          permission: 'china_region'
        }
      },
      {
        path: 'cities/new',
        component: () => import('../views/china_regions/cities/new.vue'),
        name: 'NewCity',
        hidden: true,
        meta: {
          title: '新增市',
          activeMenu: '/china_regions/cities/index',
          permission: 'china_region'
        }
      },
      {
        path: 'cities/:id/edit',
        component: () => import('../views/china_regions/cities/edit.vue'),
        name: 'EditCity',
        hidden: true,
        meta: {
          title: '编辑市',
          activeMenu: '/china_regions/cities/index',
          permission: 'china_region'
        }
      },
      {
        path: 'counties/index',
        component: () => import('../views/china_regions/counties/index.vue'),
        name: 'Counties',
        meta: {
          title: '县',
          permission: 'china_region'
        }
      },
      {
        path: 'counties/new',
        component: () => import('../views/china_regions/counties/new.vue'),
        name: 'NewCounty',
        hidden: true,
        meta: {
          title: '新增县',
          activeMenu: '/china_regions/counties/index',
          permission: 'china_region'
        }
      },
      {
        path: 'counties/:id/edit',
        component: () => import('../views/china_regions/counties/edit.vue'),
        name: 'EditCounty',
        hidden: true,
        meta: {
          title: '编辑县',
          activeMenu: '/china_regions/counties/index',
          permission: 'china_region'
        }
      },
      {
        path: 'towns/index',
        component: () => import('../views/china_regions/towns/index.vue'),
        name: 'Towns',
        hidden: true,
        meta: {
          title: '镇',
          permission: 'china_region'
        }
      }
    ]
  },

  {path: '*', redirect: '/404', hidden: true}
]

const createRouter = () => new Router({
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    // reset router
    router.matcher = newRouter.matcher
}

const originalReplace = Router.prototype.replace
const originalPush = Router.prototype.push
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router
