import {adminLoginApi, getAdminProfileApi} from '../../api/admin'
import {getToken, setToken, removeToken} from '../../utils/auth'
import {resetRouter} from '../../router'

const getDefaultState = () => {
    return {
        api_access_token: getToken(),
        name: '',
        mobile: '',
        permissions: []
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.api_access_token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_MOBILE: (state, mobile) => {
        state.mobile = mobile
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
}

const actions = {
    login({commit}, loginForm) {
        const {mobile, password} = loginForm
        return new Promise((resolve, reject) => {
            adminLoginApi({mobile: mobile.trim(), password: password.trim()}).then(response => {
                const {data} = response
                commit('SET_TOKEN', data.api_access_token)
                setToken(data.api_access_token)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    getInfo({commit, state}) {
        return new Promise((resolve, reject) => {
            getAdminProfileApi().then(response => {
                const {data} = response

                if (!data) {
                    reject('Verification failed, please Login again.')
                }

                const {username, mobile, permissions} = data

                commit('SET_NAME', username)
                commit('SET_MOBILE', mobile)
                commit('SET_PERMISSIONS', permissions)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },


    logout({commit, state}) {
        return new Promise((resolve, reject) => {
            removeToken()
            resetRouter()
            commit('RESET_STATE')
            commit('SET_PERMISSIONS', [])

            resolve()
        })
    },

    resetToken({commit}) {
        return new Promise(resolve => {
            removeToken()
            commit('RESET_STATE')
            commit('SET_PERMISSIONS', [])
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
