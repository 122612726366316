import Vue from 'vue'

// A modern alternative to CSS resets
import 'normalize.css/normalize.css'
// global css
import '../admin/styles/index.scss'
// icon
import '../admin/icons'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// lang i18n
import locale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(ElementUI, {locale})

import App from '../admin/App.vue'
import store from '../admin/store'
import router from '../admin/router'
// permission control
import '../admin/permission'

Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
    const app = new Vue({
        el: '#app',
        router,
        store,
        render: h => h(App)
    }).$mount()
})
