import request from '../utils/request'

export function adminLoginApi(data) {
    return request({
        url: '/api/admin/sessions',
        method: 'post',
        data
    })
}

export function getAdminProfileApi() {
    return request({
        url: '/api/admin/sessions/profile',
        method: 'get'
    })
}

export function getAdminListApi(params) {
    return request({
        url: '/api/admin/admins',
        method: 'get',
        params
    })
}

export function createAdminApi(data) {
    return request({
        url: '/api/admin/admins',
        method: 'post',
        data
    })
}

export function updateAdminApi(id, data) {
    return request({
        url: `/api/admin/admins/${id}`,
        method: 'put',
        data
    })
}

export function updateAdminPasswordApi(id, data) {
    return request({
        url: `/api/admin/admins/${id}/update_password`,
        method: 'post',
        data
    })
}

export function destroyAdminApi(id) {
    return request({
        url: `/api/admin/admins/${id}`,
        method: 'delete'
    })
}
