import router from './router'
import store from './store'
import {Message} from 'element-ui'
// progress bar
import NProgress from 'nprogress'
// progress bar style
import 'nprogress/nprogress.css'
import {getToken} from './utils/auth'
import getPageTitle from './utils/get-page-title'

// NProgress Configuration
NProgress.configure({showSpinner: false})

// no redirect whitelist
const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
    NProgress.start()

    document.title = getPageTitle(to.meta.title)

    const hasToken = getToken()

    if (hasToken) {
        if (to.path === '/login') {
            next({path: '/'})
            NProgress.done()
        } else {
            const hasGetUserInfo = store.getters.admin_name
            if (hasGetUserInfo) {
                next()
            } else {
                try {
                    const { is_super, permissions } = await store.dispatch('admin/getInfo')
                    const accessRoutes = await store.dispatch('permission/generateRoutes', {
                      is_super: is_super,
                      permissions: permissions
                    })
                    router.addRoutes(accessRoutes)
                    next({ ...to, replace: true })
                } catch (error) {
                    await store.dispatch('admin/resetToken')
                    Message.error(error.message || 'Has Error')
                    next(`/login?redirect=${to.path}`)
                    NProgress.done()
                }
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next(`/login?redirect=${to.path}`)
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
