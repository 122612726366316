export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

export function validUsername(str) {
    const valid_map = ['admin', 'editor']
    return valid_map.indexOf(str.trim()) >= 0
}

export function validMobile(str) {
    return /[1][3,4,5,6,7,8][0-9]{9}$/.test(str)
}
